.applaud-quote blockquote {
  position: relative;
  padding: 0 10px;
  margin: 20px 10px 0 25px;
}
.applaud-quote blockquote span.author {
  position: relative;
  display: block;
  text-align: right;
  margin-top: 15px;
  color: #999;
}
.applaud-quote blockquote span.author i {
  position: relative;
  padding-left: 5px;
  font-style: italic;
  z-index: 5;
}
.applaud-quote blockquote span.author::before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  color: #ccc;
  background-color: #8a8a8a;
}

.applaud-quote blockquote::before {
  display: block;
  position: absolute;
  top: -35px;
  left: -25px;
  content: "\"";
  font-size: 80px;
  color: #999;
}

.scrollable-section {
  overflow-y: scroll;
  max-height: 85vh;
}
